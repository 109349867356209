<template>
    <div class="view-chat"></div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { browser } from '@inconvo/utils';
import ChatMixin from '@/mixins/ChatMixin';
import { AppAction, ModalType, QueryParam, ChatWigetType, MediaQuery } from '@/enums';
import config from '@/config';
import RouterService from '@/services/router.service';

export default {
    name: 'Chat',
    layout: 'chat',
    middleware: 'channel-redirect',
    mixins: [ChatMixin],
    props: {
        convoMetadata: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            action: this.$queryParams(QueryParam.Action),
            userId: this.$queryParams(QueryParam.RecontactId),
            signInCode: this.$queryParams(QueryParam.SignInCode),
            referral: this.$queryParams(QueryParam.Referral),
            redirectPath: this.$queryParams(QueryParam.RedirectPath),
            convoId: this.$route.params.convoId && Number(this.$route.params.convoId),
            showFullPageLoader: false,
            isChatPage: true,
        };
    },
    computed: {
        ...mapState('channels', ['activeChannel']),
        ...mapState('auth', ['isLoggedIn', 'authRefreshed']),
        ...mapGetters('auth', ['emailValidationStatus', 'subscriberId']),
        channelUrl() {
            return this.channelData
                ? `${window.location.origin}/channels/${this.channelData.slug}`
                : window.location.origin;
        },
    },
    created() {
        if (this.authRefreshed) {
            this.prepareChat();
            return;
        }
        const unwatch = this.$watch('authRefreshed', () => {
            unwatch();
            this.prepareChat();
        });
    },
    mounted() {
        if (this.userId !== '') {
            this.setUserId(this.userId);
            this.setKnownUserFlag();
        }
        this.setReferral(this.referral);
        this.$chatWidget.addEventListener('channelJump', this.onChannelLogicJump);

        this.chatWidgetMediaQuery = new browser.MediaQueryService(MediaQuery.Large);
        this.chatWidgetMediaQuery.addEventListener(this.onChatWidgetMediaQueryChange);
        this.onChatWidgetMediaQueryChange(this.chatWidgetMediaQuery);
    },
    beforeDestroy() {
        this.$chatWidget.removeEventListener('channelJump', this.onChannelLogicJump);
        this.chatWidgetMediaQuery.removeEventListener(this.onChatWidgetMediaQueryChange);
    },
    methods: {
        ...mapActions('channels', ['getChannel', 'unsubscribeFromChannel']),
        ...mapActions('main', ['toggleModal', 'updateModal']),
        ...mapActions('auth', [
            'getAuthData',
            'setKnownUserFlag',
            'setUserEmail',
            'setNoSignupFlag',
            'setReferral',
            'setUserId',
            'validateRecontactToken',
        ]),
        onChannelBack() {
            this.$router.push({ name: 'channels' });
        },
        async prepareChat() {
            const channelSlug = this.$route.params.slug;

            const appActions = [
                AppAction.Subscribe,
                AppAction.Landing,
                AppAction.Signup,
                AppAction.SignupNotClosable,
                AppAction.EmailOnly,
                AppAction.SignupSocial,
            ];

            if (this.isLoggedIn) {
                if (appActions.includes(this.action)) {
                    browser.replaceHistoryState('action');
                }
            }

            if (!this.isLoggedIn) {
                if (this.action === AppAction.Subscribe || this.action === AppAction.Landing) {
                    this.openSubscribeModal(channelSlug);
                } else if (
                    [
                        AppAction.Signup,
                        AppAction.EmailOnly,
                        AppAction.SignupNotClosable,
                        AppAction.SignupSocial,
                    ].includes(this.action)
                ) {
                    let modalType = ModalType.LoginEmail;
                    let closable = [AppAction.Signup, AppAction.EmailOnly, AppAction.SignupSocial].includes(
                        this.action,
                    );

                    if (
                        config.featureToggle.dailyChatSignupOverlayEnabled &&
                        this.action === AppAction.Signup &&
                        config.dailyChatChannels.split(',').includes(channelSlug)
                    ) {
                        modalType = ModalType.DailyChatSignupModal;
                        closable = false;
                    }

                    this.toggleModal({
                        type: modalType,
                        payload: {
                            redirectPath: this.redirectPath,
                            referral: this.referral,
                            sourceChannelCode: this.activeChannel.code,
                            sourceConvoId: this.convoId,
                            closable,
                            backdropStyle: 'solid',
                            hasChannelData: true,
                            emailOnly: this.action === AppAction.EmailOnly,
                            title: this.convoMetadata?.name || this.activeChannel?.landingPage?.title || '',
                            image: this.convoMetadata?.image || this.activeChannel?.landingPage?.image || '',
                        },
                    });
                } else if (this.signInCode.length) {
                    this.toggleModal({ type: ModalType.AuthChallenge, payload: { signInCode: this.signInCode } });
                } else if (this.action === AppAction.NoSignup) {
                    this.setNoSignupFlag();
                }
            }

            this.updateModal({
                payload: {
                    data: this.activeChannel.landingPage,
                },
            });

            if (Object.keys(this.channelData).length === 0) {
                this.$sentry.captureMessage('_convoId.vue > prepareChat: 404 redirection', {
                    extra: {
                        route: this.$route,
                        location: window.location,
                        channelData: this.channelData,
                    },
                });
                return this.$nuxt.error({ statusCode: 404 });
            }

            if (config.featureToggle.useRecontactToken && this.$route.query.recontact_token) {
                await this.validateRecontactToken(this.$route.query.recontact_token);
            }

            const authData = await this.getAuthData();
            this.$chatWidget.setAuthData(authData);

            // If channels exists and data is loaded, start chat.
            const allowChatToStart = ![AppAction.Subscribe, AppAction.Landing, AppAction.LandingSms].includes(
                this.action,
            );

            if (allowChatToStart || this.subscriberId || this.isLoggedIn) {
                this.startChat();
            }
        },
        async onChannelLeave() {
            await this.unsubscribeFromChannel(this.channel);
            this.$emit('updatedSubscription');
            this.$router.push({ path: '/' });
        },
        async onChannelLogicJump({ channelSlug, convoId }) {
            await this.getChannel(channelSlug);
            this.$router.push({
                name: 'channels-slug-convoId',
                params: { slug: this.activeChannel.slug, convoId },
                query: RouterService.getRedirectParams(this.$route),
            });
        },
        openSubscribeModal() {
            if (!this.subscriberId) {
                this.toggleModal({
                    type: ModalType.Subscribe,
                    payload: {
                        redirectPath: this.redirectPath,
                        referral: this.referral,
                        closable: true,
                        backdropStyle: 'solid',
                        hasChannelData: true,
                        sourceChannelCode: this.activeChannel.code,
                        sourceConvoId: this.convoId,
                    },
                });
            } else {
                browser.replaceHistoryState('action');
            }
        },
        onChatWidgetMediaQueryChange(event) {
            if (event.matches) {
                this.$chatWidget.changeMode(ChatWigetType.ChannelView);
            } else {
                this.$chatWidget.changeMode(ChatWigetType.FullScreen);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.view-chat {
    .chat,
    .chat-placeholder {
        @include media-query(large) {
            margin: 0 auto;
            max-width: 650px;
            box-shadow: 0 0 56px 0 rgba(0, 0, 0, 0.34);
        }
    }
}
</style>
